// @generated: @expo/next-adapter@2.1.5
import { getLangsConnect } from '../common/utils/api';
import App, { getStaticPropsFunction } from './[lang]';

export async function getStaticProps() {
    const langFetchRes = await getLangsConnect({ isDefault: true });
    const activeLangs = langFetchRes?.data || [];


    let isActiveLanguage = activeLangs[0];
    if (!isActiveLanguage) {
        return { notFound: true };
    }

    const lang = isActiveLanguage.alias;

    return getStaticPropsFunction({ params: { lang } });
}
export default App;